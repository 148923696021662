import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { HeroBanner } from '../components/HeroBanner';
import { SEO } from '../components/SEO';

const PageWrapper = styled.div`
  max-width: var(--wide-width);
  margin: 0 auto;
  padding: 0 1rem;
`;

const IndexPage = () => {
  return (
    <PageWrapper>
      <SEO title={'Home'} />

      <HeroBanner text={['Crush Them All Fans']} size={8} align='left' />
      <div>
        <h1>Welcome Crushers!</h1>
        <p>
          Crush Them All is an idle RPG available on{' '}
          <a
            href='https://apps.apple.com/us/app/crush-them-all-idle-rpg/id1153329252'
            target='_blank'
            rel='noopener noreferrer'
          >
            IOS
          </a>{' '}
          and{' '}
          <a
            href='https://play.google.com/store/apps/details?id=com.godzilab.idlerpg&hl=en_US&gl=US'
            target='_blank'
            rel='noopener noreferrer'
          >
            Android
          </a>{' '}
          platforms. There are currently 116 different heroes that you can use
          to form teams to CRUSH the competition. This website contains
          everything you'll need for form the best teams for PVE and PVP
          matches.
        </p>
        <p>
          To get started, check out some <Link to='/heroes'>heroes</Link>
        </p>
      </div>
    </PageWrapper>
  );
};

export default IndexPage;
