import React from 'react';
import styled from 'styled-components';

interface Props {
  text: string[];
  size?: number;
  align?: 'left' | 'center' | 'right';
}

const HeroWraper = styled.div`
  margin: 3rem 0;
`;

interface IHeroText {
  size?: number;
  align?: 'left' | 'center' | 'right';
  random: number;
}
const HeroText = styled.div<IHeroText>`
  font-size: ${(props) => props.size}rem;
  color: var(--grey-50);
  text-align: ${(props) => props.align};
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px var(--neon),
    0 0 20px var(--neon), 0 0 25px var(--neon), 0 0 30px var(--neon),
    0 0 75px var(--neon);
  transition: all 100ms ease-in-out;
  letter-spacing: 0.15em;
  transform: skewY(${(props) => props.random}deg);
  text-align: center;
  @media (max-width: 768px) {
    font-size: 3rem;
  }
  @media (max-width: 300px) {
    font-size: 2rem;
  }
`;

export const HeroBanner = ({ text, size = 10, align = 'center' }: Props) => {
  const random = Math.random() > 0.5 ? -1 : 1;
  return (
    <HeroWraper>
      {text.map((str) => (
        <HeroText random={random} size={size} align={align} key={str}>
          {str}
        </HeroText>
      ))}
    </HeroWraper>
  );
};
